@import 'src/assets/scss/variables';

.textArea {
  @include theme(border, gray200, 1px solid);
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  text-decoration: none;
  outline: none;
  transition: all 0.3s;
  font-size: 14px;

  &:hover {
    @include theme(border-color, gray300);
  }

  &:focus {
    @include theme(border-color, primary200);
  }
}