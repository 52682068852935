.Visualizer {
  width: 400px;
  padding: 8px;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.Card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 21px 16px;
  border-radius: 8px;
  border: solid 1px #ddd;
  background-color: #fff;
}

.FOButton {
  cursor: pointer;
  width: 100%;
  max-height: 54px;
  margin: 0 0 8px;
  padding: 18px 0;
  border-radius: 8px;
  outline: none;
  border: none;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
}

.FOButtonPrimary {
  background-color: var(--fo-primary-200);
  color: white;
}

.FOButtonSecondary {
  background-color: var(--fo-secondary-200);
  color: white;
}

.FOButtonOutlined {
  color: var(--fo-primary-200);
  background-color: white;
  border: 1px solid var(--fo-primary-200);
}