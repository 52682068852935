@import 'src/assets/scss/variables';

$width: 16px;

.Label {
  @include theme(border, gray200, 1px solid);
  @include theme(color, gray400);
  //font-size: 2rem;
  font-size: 12px;
  display: grid;
  grid-template-columns: 16px auto;
  gap: 8px;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  height: 100%;
  cursor: pointer;
}

.CheckboxField.Label {
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: flex-start;
}

.Left {
  display: grid;
  grid-template-columns: 16px auto;
  gap: 8px;
  align-items: center;
}


.Label:hover {
  @include theme(border-color, gray300);
  .Checkmark {
    @include theme(border-color, gray400);
  }
}

.Input {
  margin: 0;
  position: absolute;
  opacity: 0;
  z-index: 10;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.Checkmark {
  @include theme(border, gray300, 1px solid);
  display: block;
  position: relative;
  height: $width;
  width: $width;
  border-radius: 4px;
  background-color: white;

  &:after {
    content: '';
    position: absolute;
    box-sizing: content-box;
    display: none;
    left: 50%;
    top: 50%;
    width: 2px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -60%) rotate(45deg);
  }
}

.Checkbox {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  user-select: none;

  &:hover .Checkmark {
    @include theme(border-color, gray400);
  }

  input:checked ~ .Checkmark {
    @include theme(background-color, gray400);
    border: none;
  }

  input:checked ~ .Checkmark:after {
    display: block;
  }

  &:hover input:checked ~ .Checkmark {
    @include theme(background-color, gray500);
  }
}
