@import "assets/scss/variables";

.Card {
  position: relative;
  width: 100%;
  height: 137px;
  //margin: 16px 8px 477px 16px;
  //padding: 32px 54px;
  border-radius: 16px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;

  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;

  & > span {
    @include theme(color, gray500);
    //margin: 16px 0 0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.Disabled {
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}
