@import 'src/assets/scss/variables';

.Container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  //flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.EmptyBar {
  background-color: #e7e4da;
  border-radius: 100px;
  height: 3px;
  overflow: hidden;
  position: relative;
}

.FillingBar {
  @include theme(background-color, primary200);
  transition: all 0.5s;
  //background-image: linear-gradient(to right, #666fcc, #9c77d3);
  border-radius: 100px;
  height: 100%;
  width: 100%;
  position: absolute;
}

.Text {
  @include theme(color, primary200);
  font-size: 14px;
  font-weight: bold;
}