.InfosCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InfosCardContent {
}

.InfosCardContentBase {
  max-width: 700px;
  white-space: pre-wrap;

  ul {
    padding-left: 10px;
    margin-bottom: 21px;
  }
}

.InfosCardContentFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    max-width: 650px;
    margin: 0;
    font-size: 13px;
    font-weight: bold;
  }
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 8px;
}

.CustomizationForm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.Placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  padding: 32px 16px;
  text-align: center;
}