.FirstSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 28px;
}

.Left {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  //flex-direction: column;
  //align-items: flex-start;
  gap: 8px;

  & input, & fieldset {
    width: 100%;
  }

  & textarea {
    height: 100%;
  }
}

.DescriptionField {
  display: flex;
  flex-direction: column;

  & > div {
    height: 100%;
  }
}

.Right {
  //min-height: 500px;
}

.ImgInput {
  height: 300px;
}