@import 'src/assets/scss/variables';

.IndicatorSeparator {
  display: none;
}

:global(.default) .Control {
  transition: all 0.3s;
  border-radius: 8px;
  box-shadow: none;
  min-height: 34px;

  &:hover,
  &:active {
    box-shadow: none;
  }
}

.Control:hover,
.Control:active {
  @include theme(border-color, gray300);
}

.ControlError {
  @include theme(border-color, red200);
}

.ControlFilled {
  @include theme(border-color, gray400);

  &:hover {
    @include theme(border-color, gray500);
  }
}

.MultiValue {
  @include theme(border, gray400, 1px solid);
  @include theme(background-color, primary50);
  border-radius: 3px;
}

.MultiValueLabel {
  @include theme(color, gray400);
}

:global(.default) .MultiValueLabel {
  font-weight: 700;
  font-size: 12px;
}

.MultiValueRemove {
  @include theme(color, gray400);
}

:global(.default) .DropdownIndicator {
  //@include theme(padding, 4px);
  padding: 4px;
}

.ClearIndicator {
  padding: 4px;
}

.Placeholder {
  @include theme(color, gray300);
  margin-left: 0;
}

.SingleValue {
  font-size: 14px;
  margin-left: 0;
}

.Input {
  margin-left: 0;
  margin-top: 4px;
  padding-bottom: 4px;
}

.OptionHovered:hover {
  @include theme(background-color, gray100);
}

.Option {
  @include theme(background-color, gray100);
}
