@import 'src/assets/scss/variables';

.Link {
  @include theme(color, primary200);
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    //@include theme(text-decoration, primary200);
  }
}
