@import 'src/assets/scss/variables';

.Legend {
  @include theme(color, gray300);
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &:after {
    @include theme(background-color, gray300);
    display: block;
    content: '';
    height: 1px;
    flex: 1 1 auto;
    margin-left: 16px;
  }
}