.CategoryRadioItemGroup {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.DefinitionSection {
   display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  margin-bottom: 32px;
}

.PriceSection {
  margin-bottom: 32px;
}