@import 'src/assets/scss/variables';

.Card {
  background-image: linear-gradient(to bottom, #f8f9fa, #f0f2f3);
  border-radius: 16px;
  box-shadow: none;
}

.AvailableCardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    @include theme(color, gray400);
  }

  span {
    font-weight: normal;
    font-size: 13px;
  }
}

:global(.default) .AvailableCardTitle {
  border-bottom: none;
}

.AvailableSection {
  display: grid;
  grid-template-columns: minmax(300px, auto) 1fr;
  gap: 16px;

  & .Left {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
.BottomSection {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 8px;
}

.AvailableChart {
  width: 100%;
  height: 285px;
  max-height: 285px;

  /*canvas {
    width: 100%;
  }*/
}