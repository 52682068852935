.Layout {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin-right: 16px;
}

.Layout__tabs {
  flex: 1 1 auto;
}
