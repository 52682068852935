.arrow-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.arrow-down {
  display: flex;
  margin-top: 2px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.arrow-up.ascending {
  color: #4d5258;
}
.arrow-down.ascending {
  color: #cacbcd;
}

.arrow-up.descending {
  color: #cacbcd;
}
.arrow-down.descending {
  color: #4d5258;
}

.arrow-buttons {
  position: absolute;
  display: inline-flex;
  align-items: center;
  right: 0;
  top: 0;
  width: 24px;
  height: 100%;
}

.sorting_desc {
  outline: none;
  position: relative;
}

.data-label {
  display: inline-block;
  max-width: 70%;
}
