@import 'src/assets/scss/variables';

.Root {
  @include theme(color, gray400);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  font-size: 9px;
}

.Group {
  display: flex;
  align-items: center;
  gap: 4px;
}