@import 'src/assets/scss/variables';

.Root {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.Downloaded {
  justify-content: space-between;
}

.SuccessText {
  @include theme(color, green200);
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
}