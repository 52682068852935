@import 'src/assets/scss/variables';

.Layout {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin-right: 16px;
}

.Layout__tabs {
  flex: 1 1 auto;
}

.title {
  display: flex;
  gap: 8px;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  gap: 8px;
}

.leftSection {
  grid-column: 1 / 3 span;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.leftSection {
  :global(.bookingCard) {
    flex: 1;
  }
}

.rightSection {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}

.commentarySection {
  flex: 0 0 100%;
}

.validateBookingSection {
  grid-column: 4 span;
  display: flex;
  //grid-template-columns: repeat(4, 1fr);
  gap: 8px;

  & > div {
    flex: 1 1 0;
  }
}

.BookingCard {
  & :global(.card-content) {
    display: flex;
    flex-direction: column;
  }
}

.assignCard__text {
  display: block;
  margin-bottom: 16px;
}

.assignCard__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ValidTunnelCardContent {
}

.ValidTunnelCardFooter {
  margin-top: auto;
}

.assignModal {
  min-width: 500px !important;
}

.assignModal__footer {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.finishCard {
  flex: 1 1 100%;

  .finishCard__text {
    margin: 0;
  }

  :global(.card-content) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .finishCard__button {
    flex: 0 0 auto;
  }
}

.finishModal__statusInput {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.RadioGroup {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.CreateDocumentModal__form {
  display: grid;
  gap: 16px;
}

.CreateCheckoutModal__form {
  display: grid;
  gap: 16px;
}

.managementCard {
  .managementCard__text {
    //margin: 0 20px 16px;
    //max-width: 220px;
    text-align: left;
  }

  :global(.card-content) {
    text-align: center;
  }
}

.managementCard__line {
  margin: 16px 0;
}

.managementCard__swap-text {
}

.signatureModal__canvas {
  @include theme(border, gray400, 1px solid);
  border-radius: 8px;
}

.successText {
  @include theme(color, green200);
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
}

.formControl {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
  }
}

.FooterActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PaymentInfosCardFooter {
  margin-top: auto;
}
