@import 'src/assets/scss/variables';

$width: 16px;

.Checkmark {
  @include theme(border, gray300, 1px solid);
  display: block;
  position: relative;
  height: $width;
  width: $width;
  border-radius: 4px;
  background-color: white;

  &:after {
    content: '';
    position: absolute;
    box-sizing: content-box;
    display: none;
    left: 50%;
    top: 50%;
    width: 2px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -60%) rotate(45deg);
  }
}

.Checkbox {
  &:hover .Checkmark {
    @include theme(border-color, gray400);
  }

  input:checked ~ .Checkmark {
    @include theme(background-color, gray400);
    border: none;
  }

  input:checked ~ .Checkmark:after {
    display: block;
  }

  &:hover input:checked ~ .Checkmark {
    @include theme(background-color, gray500);
  }
}
