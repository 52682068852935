.form-group {
  position: relative;
}

.form-group__errorMessage {
  z-index: 900;
  color: red;
  background-color: white;
  font-size: 0.8rem;
}

.form-group__error {
  border-color: red;
}

.form-group__error:focus {
  border-color: red;
  box-shadow: 0 0 0 0.07rem rgb(255 0 0 / 25%);
}

.icon__error {
  color: red;
}
