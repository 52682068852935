@import 'src/assets/scss/variables';

.Card {
  @include theme(color, gray400);
  border-radius: 2px;
  box-shadow: 0.5px 0.5px 0 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  font-size: 12px;
  line-height: normal;
  display: flex;
  flex-direction: column;
}

.Card.CardPrimary {
  @include theme(color, primary200);
  @include theme(background-color, primary100);
  @include theme(border, primary200, 1px solid);
  border-radius: 8px;
  padding: 8px;
}

.CardTitle {
  @include theme(border-bottom, gray200, 1px solid);
  padding: 16px;
  font-size: 16px;
  font-weight: bold;

  h3 {
    font-size: 16px;
    font-weight: bold;
  }
}

:global(.card-content) {
  height: 100%;
  padding: 16px;
}

.CardPrimary {
  :global(.card-content) {
    padding: 0;
  }
}

.CardDisabled {
  opacity: 0.64;
  pointer-events: none;
}
