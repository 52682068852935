.modal {
  margin: 2rem;
}

.modal__header {
  text-align: center;
}

.modal__header__title {
  font-size: 22px;
  font-weight: bold;
}

.modal__content {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__footer {
  display: flex;
  justify-content: center;
}

.modal__footer__button {
  max-width: 4rem;
}
