@import 'src/assets/scss/variables';

.Title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    @include theme(color, gray400);
    font-size: 16px;
    //font-weight: bold;
    opacity: 1;
  }

  span {
    font-weight: normal;
    font-size: 13px;
  }
}

.FirstSection {
  display: grid;
  grid-template-columns: minmax(246px, auto) 1fr;
  align-items: flex-start;
  gap: 8px;

  padding-bottom: 32px;
}

.SecondSection {
  h4 {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 16px 0;
    letter-spacing: 0;
    line-height: normal;
  }
}