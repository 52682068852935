@import '../../../assets/scss/variables';

.Button {
  border-radius: 4px;
  padding: 8px;
  min-height: 32px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: transparent;
  line-height: normal;

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

.Button.ButtonMajor {
  @include theme(background-color, primary200);
  color: white;
  font-weight: normal;
  text-transform: uppercase;
  padding: 12px;
  border-radius: 12px;

  &:hover {
    @include theme(background-color, primary300);
  }
}

.Button.ButtonDanger {
  @include theme(color, 'red200');
  @include theme(border, 'red200', 1px solid);
  background-color: transparent;
  border-radius: 4px;

  &:not([disabled]):hover {
    @include theme(background-color, red100);
    @include theme(border-color, red300);
  }

  /*&:not([disabled]):active {
    background-color: ${getColor('primary.100')(props)};
  }*/

  :is([disabled]) {
    @include theme(color, 'red300');
    cursor: not-allowed;
    background-color: white;
  }
}

.Button.ButtonPrimary {
  @include theme(background-color, 'gray400');
  color: white;

  &:hover:not([disabled]) {
    @include theme(background-color, 'gray500');
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.Button.ButtonSecondary {
  @include theme(color, 'gray400');
  @include theme(border, 'gray200', 1px solid);
  background-color: transparent;
  border-radius: 4px;

  &:not([disabled]):hover {
    @include theme(background-color, primary50);
    @include theme(border-color, primary50);
  }

  /*&:not([disabled]):active {
    background-color: ${getColor('primary.100')(props)};
  }*/

  :is([disabled]) {
    @include theme(color, 'gray300');
    cursor: not-allowed;
    background-color: white;
  }
}

.IconButton {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 32px;
  height: 32px;
  transition: all 0.15s ease-in;
}

.IconButton__primary {
  @include theme(color, gray400);

  &:hover {
    @include theme(color, gray500);
    background-color: white;
  }

  &:active {
    @include theme(background-color, gray100);
  }
}

.IconButtonDanger {
  @include theme(color, red200);

  &:hover,
  &:active {
    //@include theme(color, primary300);
    @include theme(background-color, red100);
  }
}

.IconButtonAction {
  @include theme(background-color, gray200);
  color: white;

  &:not([disabled]) {
    @include theme(background-color, gray400);
  }

  &:not([disabled]):hover {
    @include theme(background-color, gray300);
  }

  &:not([disabled]):active {
    @include theme(background-color, gray300);
  }
}

.ButtonWithIcon {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin: 0;
}
