@import 'src/assets/scss/variables';

.Title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    @include theme(color, gray400);
    font-size: 16px;
    //font-weight: bold;
    opacity: 1;
  }

  span {
      font-weight: normal;
      font-size: 13px;
  }
}

.FirstSection {
  @include theme(border-bottom, gray200, 1px solid);
  display: grid;
  grid-template-columns: minmax(246px, auto) 1fr;
  align-items: flex-start;
  gap: 8px;

  padding-bottom: 32px;
}

.SecondSection {
  @include theme(border-bottom, gray200, 1px solid);

  display: grid;
  grid-template-columns: minmax(246px, auto) 1fr;
  align-items: flex-start;
  gap: 8px;

  margin-top: 32px;
  padding-bottom: 32px;
}

.ThirdSection {
  margin-top: 32px;

  display: grid;
  grid-template-columns: minmax(246px, auto) 1fr;
  grid-template-rows: 1fr;
  align-items: flex-start;
  gap: 8px;

  & > div {
    height: 100%;
  }
}

.UsageBlock {
  display: flex;
  align-items: flex-end;
}