@import 'variables';
@import 'base';

html {
  @include generateThemeVariables;
  height: 100%;
}

/*strong {
  @include theme(color, gray400);
}*/

.grid {
  display: grid;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.block {
  display: block;
}

.row {
  display: flex;
  margin: 0;
}

.flex-1 {
  flex: 1 1 auto;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.start-1 {
  grid-column: 1;
}

.v-gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 1rem;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.p-8 {
  padding: 0.5rem;
}

.p-16 {
  padding: 1rem;
}

.justify-end {
  justify-content: flex-end;
}

.field--full {
  display: flex;
  width: 100%;

  & > div {
    width: 100%;
  }
}

.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bold {
  font-weight: bold !important;
}

.w-full {
  width: 100%;
}

.radio-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.radio-group.big {
  grid-template-columns: repeat(4, 1fr);
}

.box-field {
  @include themeWithoutGlobal(border-color, gray200);
  padding: 16px;
  border: 1px solid;
  border-radius: 4px;
  display: grid;
  justify-content: space-between;
  align-items: flex-start;
  grid-template-columns: auto auto;
}

.SingleDatePicker_picker__portal {
  z-index: 10000;
}

.DateInput_input {
  @include themeWithoutGlobal(border, gray200, 1px solid);
  display: block;
  background-color: white;
  outline: none;
  font-size: 14px;
  padding: 8px;
  line-height: normal;
  border-bottom: 0;
  border-radius: 8px;
  transition: all 0.3s;

  &:hover {
    @include themeWithoutGlobal(border, gray400, 1px solid);
  }

  &:not(:placeholder-shown) {
    @include themeWithoutGlobal(border-color, gray400);
  }
}

.DateInput {
  border-radius: 8px;
}

.SingleDatePickerInput__withBorder {
  border-radius: 8px;
}

.DateRangePickerInput {
  display: grid;
  align-items: center;
  grid-template-columns: auto 0 auto;
  gap: 8px;
}

.DateInput_input__focused.DateInput_input {
  @include themeWithoutGlobal(border, primary200, 1px solid);
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  align-items: center;
}

.mandatory-fields-notice {
  @include themeWithoutGlobal(color, gray400);
  font-size: 13px;
  font-weight: normal;
}

.legend-tooltip {
  padding: 16px;
  background: white;
  border-radius: 6px;

  ul {
    padding: 0;
    margin: 0;
  }
}

.legend {
  list-style: none;
  display: flex;
  gap: 16px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  span {
    display: inline-block;
    width: 16px;
    height: 8px;
    border-radius: 4px;
  }

  p {
    margin: 0;
    font-size: 10px;
    color: #666666;
  }

  &.hidden {
    p {
      text-decoration: line-through;
    }
  }
}