@import 'src/assets/scss/variables';

.Button {
  @include theme(background-color, gray400);
  //width: 64px;
  height: 100%;
  //margin: 0 16px 0 0;
  padding: 24px;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;

  &:focus-visible,
  &:focus {
    outline: none;
  }

  & > div {
    position: relative;
    //margin: 5rem auto;
    display: flex;
    justify-content: center;
    align-items: center;

    & > span {
      position: absolute;
      top: 50%;
      //margin-top: -5px;
      width: 20px;
      height: 2px;
      background-color: white;
      border-radius: 20px;

      &:before {
        position: absolute;
        content: '';
        width: inherit;
        height: inherit;
        background-color: inherit;
        top: -7px;
        left: 0;
        right: 0;
        border-radius: inherit;
      }

      &:after {
        position: absolute;
        content: '';
        width: inherit;
        height: inherit;
        left: 0;
        right: 0;
        background-color: inherit;
        bottom: -7px;
        border-radius: inherit;
      }
    }
  }
}