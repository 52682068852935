@import '~react-datepicker/dist/react-datepicker.css';

.react-datepicker-wrapper {
  flex: 1;
}

.react-datepicker__input-container {
  height: 100%;
}
.form-control.form-control-lg.datepicker {
  height: 100%;
  padding: 0.375rem 1rem;
}
