@font-face {
    font-family: 'Roboto';
    font-weight: normal;
    src: url('/public/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: bold;
    src: url('/public/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, sans-serif;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    box-sizing: border-box;
}

main {
    min-height: 100vh;
    height: 100%;
}

.reset {
    line-height: normal;
    min-height: 100%;
    flex: 1 1 auto;
    letter-spacing: normal;
}

.soft-reset {
    min-height: 100%;
    flex: 1 1 auto;
}
