@import 'src/assets/scss/variables';

.Root {
  line-height: normal;
  width: 300px;
  background-color: #414b5a;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: auto;
}

.WithTop.Root {
  grid-template-rows: auto auto 1fr auto;
}