.Form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.TitleField {
  grid-column-start: 1;
}

.FormFooter {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
