@import 'src/assets/scss/variables';

.Row {
  display: grid !important;
  gap: 8px;
  padding: 16px;
}

.DataGrid__content .Row:hover {
  //@include theme(background-color, gray200);
  background-color: #f2f2f2;
}

.Row__item {
  @include theme(color, gray400);
  font-size: 12px;
  display: flex;
  align-items: center;
}

.DataGrid__content {
  .Row:not(:hover):nth-child(odd) {
    @include theme(background-color, gray100);
  }
}
