@import 'src/assets/scss/variables';

.DropzoneFileInput {
  @include theme(background-color, gray100);
  @include theme(border, gray300, 1px dashed);
  display: flex;
  justify-content: center;
  padding: 40px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  /*width: 150px;
  height: 70px;*/

  &:hover {
    @include theme(background-color, gray200);
  }

  /*:hover ${UploadIconWrapper} {
  color: white;
  background-color: ${(props) => props.theme.color.gray['300']};
}*/

  &:focus-visible {
    outline: none;
  }
}

.PDFViewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.requiredText {
  position: absolute;
  top: 8px;
  right: 8px;
}

.small.DropzoneFileInput {
  padding: 20px;
}

.filled.small.DropzoneFileInput {
  padding: 0;
}

.DropzoneFileInput__content {
  @include theme(color, gray400);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  font-size: 12px;

  span.bold {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .advice {
    @include theme(color, gray300);
    margin-top: 26px;
    display: block;
    font-size: 10px;
  }

  // TODO: reset purpose (remove after adding reset.css)
  p {
    margin: 0;
  }
}

.DropzoneFileInput__deleteButton {
  @include theme(color, red200);
  position: absolute;
  z-index: 40;
  bottom: 8px;
  right: 8px;
  //transform: translate(-50%, -50%);
}

.small .DropzoneFileInput__deleteButton {
  bottom: 4px;
  right: 4px;
}

.DropzoneFileInput__viewer {
  //padding: 10px;
  /*position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;*/
  width: 150px;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.small .DropzoneFileInput__viewer {
  padding: 0;
}

.DropzoneFileInput__img {
  width: 100%;
  max-width: 200px;
}

.small .DropzoneFileInput__img {
  max-width: 70px;
}

.DropzoneFileInput__upload {
  @include theme(border, gray300, 1px solid);
  @include theme(color, gray300);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 8px;
  width: 32px;
  height: 32px;
  justify-self: center;
}
