@import '../../../../../assets/scss/variables';

.Line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.Line__title {
  @include theme(color, gray400);
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  letter-spacing: normal;
  line-height: normal;
  //margin-bottom: 2px;
}
