@import 'src/assets/scss/variables';

.Input {
  @include theme(border, gray200, 1px solid);
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
  line-height: normal;
  transition: 0.3s all;

  &:active,
  &:hover,
  &:focus-visible {
    @include theme(border, gray400, 1px solid);
    outline: none;
  }

  /*&:not(:placeholder-shown) {
    @include theme(border, gray400, 1px solid);
  }*/
}

.InputWithValue {
  @include theme(border, gray400, 1px solid);
}

.Wrapper {
  outline: none;

  &:active,
  &:focus-within,
  &:focus-visible {
    outline: none;
  }

  & > div {
    &:active,
    &:focus-within,
    &:focus-visible {
      outline: none;
    }
  }
}
