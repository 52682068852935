@import 'src/assets/scss/variables';

.Root {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;

  & > svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

  }

  &.IsValid > svg {
    @include theme(color, success200);
  }
}

.Input {
  @include theme(border, 'gray200', 1px solid);
  display: block;
  background-color: white;
  outline: none;
  font-size: 14px;
  border-radius: 8px;
  padding: 8px;
  transition: all 0.3s;
  width: 100%;
  text-overflow: ellipsis;

  &:disabled {
    @include theme(background-color, 'gray200');
  }

  &::placeholder {
    @include theme(color, 'gray300');
  }

  &:hover {
    @include theme(border-color, 'gray300');
  }

  &:focus,
  :not([value='']):not(:placeholder-shown) {
    @include theme(border-color, 'primary200');
  }

  &:not([value='']):not(:placeholder-shown):hover {
    @include theme(border-color, 'primary300');
  }
}

.IsValid {
  &:hover {
    @include theme(border-color, gray300);
  }

  &:focus,
  &:not([value='']):not(:placeholder-shown) {
    @include theme(border-color, primary300);
  }

  &:not([value='']):not(:placeholder-shown):hover {
    @include theme(border-color, primary300);
  }
}