.List {
  margin: 8px;

  :global(.datagrid-content) {
    display: grid;
    gap: 4px;
    padding: 8px;
    background: #f8f9fa;
    border: solid 1px #ebebeb;
    border-radius: 8px;
  }

  :global(.datagrid-cols-header-row) {
    padding: 16px 24px 22px 24px;
  }
}
