@import 'src/assets/scss/variables';

.FormControl {
  @include theme(border, gray200, 1px solid);
  @include theme(color, gray400);
  //font-size: 2rem;
  font-size: 12px;
  display: grid;
  grid-template-columns: 16px auto;
  gap: 8px;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  height: 100%;

  cursor: pointer;
}

.FormControl__selected {
  @include theme(border-color, gray400);
}

.Input {
  @include theme(border, gray300, 1px solid);
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;

  display: grid;
  place-content: center;
}

.FormControl:hover {
  @include theme(border-color, gray300);
  .Input {
    @include theme(border-color, gray400);
  }
}

.Input::before {
  @include theme(background-color, gray400);
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  scale: 0;
  //transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

.Input:checked:before {
  scale: none;
  //transform: scale(1);
}

/*.Input:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}*/

.FormControl:focus-within {
  color: var(--form-control-color);
}

.Disabled {
  cursor: not-allowed;

  input {
    cursor: not-allowed;
  }
}
