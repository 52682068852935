@import "assets/scss/variables";

.Tag {
  @include theme(background-color, gray500);
  //width: 116px;
  height: 24px;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  display: inline-block;
}