@import 'src/assets/scss/variables';

.Wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1000;
  inset: 0;
}

.Container {
  outline: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Backdrop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.66);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

.Dialog {
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 600px;
  min-width: 300px;
  background-color: white;
  border-radius: 32px;
  padding: 32px;
  border: none;
  outline: none;
  margin: 0;
}

.Dialog__title {
  @include theme(color, gray400);
  @include theme(border-bottom, gray200, 1px solid);
  font-size: 16px;
  padding-bottom: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*.Dialog__title {

}*/

.Dialog__content {
  @include theme(color, gray400);
  padding: 16px 0;

  p {
    margin: 0;
  }
}

.Dialog__actions {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
