.Form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.Form__footer {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
