@import 'src/assets/scss/variables';

.topBar {
  background-color: white;
  height: 64px;
  box-shadow: 4px 0 5px 0 rgba(0, 0, 0, 0.08);
  line-height: normal;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  @include for-size(desktop-up) {
    grid-template-columns: 1fr;
  }
}

.Content {
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
}

.topBar__title {
  @include theme(color, gray400);
  margin: 0;
  font-size: 19px;
  font-weight: bold;
}

.topBar__user {
  margin-right: 10px;
}

.topBar__rightSection {
  display: grid;
  align-items: center;
  gap: 8px;
  grid-template-columns: repeat(3, auto);
}

.Left {
  display: flex;
  align-items: center;
  gap: 8px;
}