@import 'src/assets/scss/variables';

.Tag {
  padding: 2px 4px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  letter-spacing: normal;
  line-height: normal;
}

.TagPrimary {
  @include theme(color, primary200);
  @include theme(background-color, primary100);
  @include theme(border-color, primary200);
}

.TagPlainPrimary {
  @include theme(background-color, primary200);
  @include theme(border-color, primary200);
  color: white;
}

.TagError {
  @include theme(color, red200);
  @include theme(background-color, red100);
  @include theme(border-color, red200);
}

.TagSuccess {
  @include theme(color, green200);
  @include theme(background-color, green100);
  @include theme(border-color, green200);
}

.TagYellow {
  @include theme(color, yellow200);
  @include theme(background-color, yellow100);
  @include theme(border-color, yellow200);
}
