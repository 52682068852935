@import 'src/assets/scss/variables';

.DateRangeBoxField {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  span {
    @include theme(color, gray400);
    font-size: 12px;
    font-weight: bold;
  }
}

.Footer {

}