@import 'src/assets/scss/variables';

.Tab {
  @include theme(color, gray300);
  height: 100%;
  background-color: transparent;
  padding: 20px 24px 0 24px;
  /*padding-bottom: 16px;
  margin: 20px 24px 0 24px;*/
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  border: none;

  &:hover {
    @include theme(background-color, gray200);
  }

  &.Mui-selected,
  &:global(.active) {
    @include theme(color, gray400);
  }
  /*
  &.Mui-selected:after,
  &:global(.active):after {

  }*/
}

.Tab__content {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 20px;
}

.Tab:global(.active) .Tab__content:after {
  @include theme(background-color, gray400);
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
}
