@import 'src/assets/scss/variables';

.Wrapper {
  @include theme(border, gray400, 1px solid);
  @include theme(color, gray400);
  //font-size: 2rem;
  font-size: 9px;
  display: grid;
  grid-template-columns: auto;
  gap: 8px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  height: 100%;

  cursor: pointer;
}

.Selected {
  @include theme(background-color, gray400);
  color: white !important;
}

.Input {
  display: none;
  //@include theme(border, gray300, 1px solid);
  appearance: none;
  /*background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;*/
  cursor: pointer;

  /*display: grid;
  place-content: center;*/
}

.Wrapper:hover {
  @include theme(border-color, gray300);
  .Input {
    @include theme(border-color, gray400);
  }
}

/*.Input::before {
  @include theme(background-color, gray400);
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  scale: 0;
  //transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

.Input:checked:before {
  scale: none;
  //transform: scale(1);
}*/

/*.Input:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}*/

.Wrapper:focus-within {
  color: var(--form-control-color);
}

.Disabled {
  cursor: not-allowed;

  input {
    cursor: not-allowed;
  }
}
