@import 'src/assets/scss/variables';

.Card {
  @include theme(border, gray200, 1px solid);
  border-radius: 16px;
  padding: 16px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Title {
  @include theme(color, gray400);
  font-size: 16px;
  font-weight: normal;
  opacity: 1;
  //max-width: 214px;
}

.Number {
  @include theme(color, gray400);
  font-size: 48px;
  font-weight: bold;
}

.Small .Number {
  font-size: 26px;
}

.Subtitle {
  @include theme(color, gray300);
  display: block;
  font-size: 12px;
  font-weight: normal;
  //max-width: 150px;
  white-space: pre-line;
}

.Chart {
  padding: 32px;
}