@import 'src/assets/scss/variables';

.Big.Label {
  --margin: 16px;
}

.NoMargin.Label {
  & + div {
    margin: 0;
  }
}

.Label {
  --margin: 8px;
  @include theme(color, gray400);
  font-size: 12px;
  margin-bottom: 0;
  font-weight: bold;
  width: auto;
  letter-spacing: normal;
  line-height: normal;

  &[aria-disabled='true'] {
    opacity: 0.2;
  }

  & + div {
    margin-top: 8px;
  }

  & > div {
    margin-top: var(--margin);
  }

  span ~ & {
    font-weight: normal;
  }
}

.Secondary {
  @include theme(color, gray300);
  font-weight: normal;
}
